import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

import avatar1 from '../../assets/images/user/avatar-1.jpg'

import clubLogo from '../../images/club_logo.svg'
import tombrasil from '../../assets/images/logos-tom-brasil.jpg'
import alma from '../../assets/images/logo-alma.png'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreate } from '../components/userCreate'
// import { OrganizationCreate } from './createOrganization'
import { useForm } from 'react-hook-form'
import { EventCreate } from './createEvent'
import { AddProvider } from './addProvider'
import SupplierBiometryTable from '../components/supplierBiometryTable'
import { SingleCheckIn } from './singleCheckIn'
import { SingleCreateUser } from './singleCreateUser'

function SupplierCurrentEvent() {
    // const auth = sessionStorage.getItem('auth')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    // const [userList, setUserList] = useState<any[]>([])


    const dateConvert = (date: any) => {
        const eventDate = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        return `${eventDate.toLocaleDateString(
            'pt-br'
        )} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
    }

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [eventNumber, setEventNumber] = useState(0)

    const [disableSign, setDisableSign] = useState(false)

    const [suppliers, setSuppliers] = useState<any[]>([])
    const [eventData, setEventData] = useState<any>({})

    const [deletSupplier, setDeletSupplier] = useState<any>([])

    const [isLoading, setLoading] = useState(false)

    const [acummulated, setAcummulated] = useState<any[]>([])

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0,
        count: 0
    })

    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    const confirExcludeSupplier = () => {
        handleCloseConfirm()

        setLoading(true)
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_delete}/${deletSupplier[0]}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }, body: JSON.stringify({
                eventId: deletSupplier[1]
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                getEventDetails(eventId)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const getEventDetails = (eventId) => {
        setLoading(true)
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_full_data}/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setSuppliers(response.suppliers)
                setEventData(response)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }


    const onSubmit = (data: any) => {
        setDisableSign(true)

        // const username = string_to_slug(data.name)

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                password: `AM${Math.random().toString(36).slice(-8)}!`,
                role: data.type || 'default'
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getEventEntrances = (eventId) => {
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_access}/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                if (eventNumber !== response) {
                    setEventNumber(response)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const resetForm = () => {
        setDisableSign(false)
        reset()
    }
    const { eventId } = useParams()

    useEffect(() => {
        getEventDetails(eventId)
        getEventEntrances(eventId)
        setInterval(() => {
            getEventEntrances(eventId)
        }, 10000)
    }, [])

    function biometricTotalHandler(total, done) {
        // console.log(total, done)
        const temptotal = totalUsers.count + total
        const tempwithbio = totalUsers.withBiometry + done
        setAcummulated([{ total, done }])

        console.log(acummulated)
        setTotalUsers({
            withBiometry: tempwithbio,
            count: temptotal,
            withoutBiometry: temptotal - tempwithbio
        })
    }

    return (
        <>

            <Row>
                <Col sm={12} xl={8}>
                    <Card className="Recent-Users">
                        <Card.Header>



                            <Form>
                                <Row className="align-items-center">
                                    <Col sm={12} md={4}>
                                        <Card.Title as="h5">
                                            <i className={`fa fa-circle text-c-green f-12 m-r-15`} />
                                            {eventData.name}</Card.Title>
                                        <h6 className="text-muted biometry-status m-t-10">
                                            {dateConvert(eventData.eventDate)}
                                        </h6>
                                    </Col>
                                    {/* <Col sm={12} md={3}>
                                        <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                                            Evento
                                        </Form.Label>
                                        <Form.Control id="inlineFormInputName" placeholder="CNPJ" />
                                    </Col>

                                    <Col sm={12} md={3}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Biometrias incompletas"
                                        />
                                    </Col>


                                    <Col sm={12} md={3} lg={2} >
                                        <Button className='w-100' type="submit">Pesquisar</Button>
                                    </Col> */}
                                </Row>
                            </Form>

                        </Card.Header>
                        <Card.Body className="px-0 py-2">
                            <Table responsive hover>

                                <thead>
                                    <tr>
                                        <th></th>
                                        {/* <th>CNPJ</th> */}
                                        <th>Fornecedor</th>
                                        <th className='text-center'>Colaboradores</th>
                                        <th colSpan={2}>Biometrias feitas</th>
                                        <th colSpan={2} className='text-center'>Ações</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    {suppliers &&
                                        suppliers.map((supplier: any, index) => (

                                            <tr key={index} className="unread">
                                                <td><Link to={`/provider/events/${eventId}/${supplier.id}`} className="label theme-bg2 text-white f-12">
                                                    Prestadores
                                                </Link></td>
                                                {/* <td>
                                                    <h6 className="mb-1">{supplier.cnpj}</h6>
                                                </td> */}
                                                <td>
                                                    <h6 className="mb-1">{supplier.name}</h6>
                                                </td>


                                                <SupplierBiometryTable biometricTotalHandler={biometricTotalHandler} supplierId={supplier.id} supplierName={supplier.name} />



                                                <td>

                                                    <Link onClick={() => { setDeletSupplier([supplier.id, eventData.id]); handleShowConfirm() }} className="link-coordinator text-c-red" to={''}>
                                                        excluir
                                                    </Link>
                                                </td>
                                            </tr>


                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={6} xl={4}>
                    <Card className="card-access">
                        <Card.Header>
                            <h6 className='text-muted'>Reconhecimentos</h6>
                        </Card.Header>
                        <Card.Body>
                            <div className="row align-items-center justify-content-center card-active">

                                <h6 className="text-center m-b-10 d-relative">
                                    <span className="text-numbers text-c-blue">{eventNumber}</span>
                                    <i className="feather icon-chevrons-up f-20 m-r-15 text-c-blue" />
                                    <span className="text-muted m-r-5">Entraram</span>
                                </h6>
                                {/* <ProgressBar variant="danger" label={'erro'} now={100} /> */}
                            </div>
                        </Card.Body>
                    </Card>


                    {/* <Card>
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-check-circle f-30 text-c-green" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">{totalUsers.withBiometry}</h3>
                                    <span className="d-block text-uppercase">Biometrias Completas</span>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Body>
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-alert-circle f-30 text-c-yellow" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">{totalUsers.withoutBiometry < 0 ?
                                        0 : totalUsers.withoutBiometry}</h3>
                                    <span className="d-block text-uppercase">Biometrias Faltantes</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card> */}


                    {/* <AddProvider /> */}


                    <SingleCreateUser />

                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar coordenador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showOrg} onHide={handleCloseOrg}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Local</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('email')}
                                />
                            </Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Data/Hora</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Atualizar
                                    </Button>
                                </Form.Group>
                            </Col>

                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={confirExcludeSupplier}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SupplierCurrentEvent
