import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { Controller, useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { ChromePicker } from 'react-color';


registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'

const maskCPF = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

export function OrganizationCreate() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset,
        control
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [disableSign, setDisableSign] = useState(false)

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    const [colorPickers, setColorPickers] = useState<any>({
        primary: '',
        secondary: '',
        tertiary: '',
        background_one: '',
        background_two: ''
    })

    const [showcolorPickers, setshowColorPickers] = useState<any>({
        primary: false,
        secondary: false,
        tertiary: false,
        background_one: false,
        background_two: false,
    })

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }




    const signOut = async () => {
        try {
            await auth.signOut()
            return true
        } catch (error) {
            return false
        }
    };


    const quemta = () => {

        auth.onAuthStateChanged(() => {

            const user = auth.currentUser;
            if (user) {
                const uid = user.uid;
                console.log('minhapika', uid)
            } else {
                console.log('ta na milonga')
            }
        });

    }

    const onSubmit = (data: any) => {
        // setDisableSign(true)

        const companyId = sessionStorage.getItem('company')

        const reader: any = new FileReader();
        reader.readAsDataURL(data.logo[0]);

        reader.onload = () => {
            data.logo = reader.result.replace(/^data:.+;base64,/, '');

            fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.companies}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': BASE.api.api_key
                },
                body: JSON.stringify({
                    "name": data.name,
                    "logo": data.logo,
                    "main_color": colorPickers.primary,
                    "secondary_color": colorPickers.secondary,
                    "tertiary_color": colorPickers.tertiary,
                    "background_color": `["${colorPickers.background_one}", "${colorPickers.background_two}"]`,
                    "status": "active",
                    "bepass_logo": data.bepass_logo ? 'white' : '',
                    "default_step": data.default_step ? 'face' : '',
                    "isActive": true,
                })
            })
                .then((res) => res.json())
                .then((response) => {
                    setDisableSign(false)
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error)
                })
        };


        return false;


    }

    const resetForm = () => {
        setNewUser({
            login: '',
            password: '',
            set: false
        })
        setHasToken('')
        setDisableSign(false)
        reset()
    }

    const handleColorPicker = (color, event, targetName) => {

        const preload = colorPickers

        if (targetName === 'primary') {
            setColorPickers({ ...preload, primary: color.hex })
        }
        if (targetName === 'secondary') {
            setColorPickers({ ...preload, secondary: color.hex })
        }
        if (targetName === 'tertiary') {
            setColorPickers({ ...preload, tertiary: color.hex })
        }
        if (targetName === 'background_one') {
            setColorPickers({ ...preload, background_one: color.hex })
        }
        if (targetName === 'background_two') {
            setColorPickers({ ...preload, background_two: color.hex })
        }

    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as="h5">Criar nova</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label>Nome Fantasia</Form.Label>
                                        <Form.Control
                                            type="name"
                                            placeholder=""
                                            {...register('name')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label>Logo</Form.Label>
                                        <Form.Control
                                            type='file'
                                            placeholder=""
                                            {...register('logo')}
                                        />
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col sm={6} xl={4}>
                                        <Form.Label onClick={() => setshowColorPickers({ primary: !showcolorPickers.primary })}>Cor Principal</Form.Label>
                                        <Form.Control
                                            type="hidden"
                                            value={colorPickers.primary}
                                            {...register('main_color')}
                                        />
                                        <div onClick={() => setshowColorPickers({ primary: !showcolorPickers.primary })} className='pickerColor' style={{ backgroundColor: colorPickers.primary }} />
                                        {showcolorPickers.primary &&
                                            (
                                                <ChromePicker
                                                    color={colorPickers.primary}
                                                    onChangeComplete={(color, event) => handleColorPicker(color, event, 'primary')}
                                                />)}
                                    </Col>

                                    <Col sm={6} xl={4}>
                                        <Form.Label onClick={() => setshowColorPickers({ secondary: !showcolorPickers.secondary })}>Cor Secundária</Form.Label>
                                        <Form.Control
                                            type="hidden"
                                            value={colorPickers.secondary}
                                            {...register('second_color')}
                                        />
                                        <div onClick={() => setshowColorPickers({ secondary: !showcolorPickers.secondary })} className='pickerColor' style={{ backgroundColor: colorPickers.secondary }} />
                                        {showcolorPickers.secondary &&
                                            (
                                                <ChromePicker
                                                    color={colorPickers.secondary}
                                                    onChangeComplete={(color, event) => handleColorPicker(color, event, 'secondary')}
                                                />)}
                                    </Col>

                                    <Col sm={6} xl={4}>
                                        <Form.Label onClick={() => setshowColorPickers({ tertiary: !showcolorPickers.tertiary })}>Cor Terciária</Form.Label>
                                        <Form.Control
                                            type="hidden"
                                            value={colorPickers.tertiary}
                                            {...register('third_color')}
                                        />
                                        <div onClick={() => setshowColorPickers({ tertiary: !showcolorPickers.tertiary })} className='pickerColor' style={{ backgroundColor: colorPickers.tertiary }} />
                                        {showcolorPickers.tertiary &&
                                            (
                                                <ChromePicker
                                                    color={colorPickers.tertiary}
                                                    onChangeComplete={(color, event) => handleColorPicker(color, event, 'tertiary')}
                                                />)}
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label onClick={() => setshowColorPickers({ background_one: !showcolorPickers.background_one })}>Bg Principal</Form.Label>
                                        <Form.Control
                                            type="hidden"
                                            value={colorPickers.background_one}
                                            {...register('third_color')}
                                        />
                                        <div onClick={() => setshowColorPickers({ background_one: !showcolorPickers.background_one })} className='pickerColor' style={{ backgroundColor: colorPickers.background_one }} />
                                        {showcolorPickers.background_one &&
                                            (
                                                <ChromePicker
                                                    color={colorPickers.background_one}
                                                    onChangeComplete={(color, event) => handleColorPicker(color, event, 'background_one')}
                                                />)}
                                    </Col>
                                    <Col>
                                        <Form.Label onClick={() => setshowColorPickers({ background_two: !showcolorPickers.background_two })}>Bg Complementar</Form.Label>
                                        <Form.Control
                                            type="hidden"
                                            value={colorPickers.background_two}
                                            {...register('third_color')}
                                        />
                                        <div onClick={() => setshowColorPickers({ background_two: !showcolorPickers.background_two })} className='pickerColor' style={{ backgroundColor: colorPickers.background_two }} />
                                        {showcolorPickers.background_two &&
                                            (
                                                <ChromePicker
                                                    color={colorPickers.background_two}
                                                    onChangeComplete={(color, event) => handleColorPicker(color, event, 'background_two')}
                                                />)}
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Logo BePass branco no rodapé?"
                                            {...register('bepass_logo')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Ir Direto para a captura?"
                                            {...register('default_step')}
                                        />
                                    </Col>
                                </Row>
                                {/* 
                            <Row className="mb-3">

                                <Col>
                                    <div className="tags-input-container">
                                        <Form.Label>Categorias:</Form.Label>
                                        <input onKeyDown={handleKeyDown} type="text" className="tags-input form-control" placeholder="Digite uma categoria e aperte ENTER" />
                                        {tags.map((tag, index) => (
                                            <div className="tag-item" key={index}>
                                                <span className="text-muted biometry-status m-t-10">{tag}
                                                    <i className="fa fa-times text-c-red close m-l-10" onClick={() => removeTag(index)}></i>
                                                </span>

                                            </div>
                                        ))}
                                    </div></Col>
                            </Row> */}
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Button
                                                disabled={disableSign}
                                                type="submit"
                                                className="mb-0 w-100"
                                                onClick={() => clearErrors()}
                                            >
                                                Cadastrar
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                            </Form>
                        </Col>
                    </Row>

                </Card.Body>
            </Card >
            {(showcolorPickers.primary ||
                showcolorPickers.secondary ||
                showcolorPickers.tertiary ||
                showcolorPickers.background_one ||
                showcolorPickers.background_two) &&
                (<div className='pikerpop' onClick={() => {
                    console.log('oie'); setshowColorPickers({
                        primary: false,
                        secondary: false,
                        tertiary: false,
                        background_one: false,
                        background_two: false,
                    })
                }}></div>)
            }
        </>
    )
}
