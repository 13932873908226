import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'


registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { Link } from 'react-router-dom';

const maskCPF = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

export function SingleProvider() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [showProviderList, setShowProviderList] = useState(false)

    const [disableSign, setDisableSign] = useState(false)

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })


    const [providerList, setProviderList] = useState<any[]>([
        {
            "name": "SecCutiry",
            "access_code": null,
            "token": "$2a$10$r8Ah7cr4m1RAgwVAq8oT7.n5gOjqGxQlehvfITN9ZPWbKUkHsJdff",
            "externalId": "190",
            "hikId": "da85ecda9d0011eda8fc0242ac120002",
            "active": true,
            coordinator: "Lucas Chaves",
            "syncStatus": [

            ]
        },
        {
            "name": "Clara Gourmet",
            "access_code": null,
            "token": "$2a$10$szP4h29fFYRZEk3iZZ7iheEE3a4yynOq22iWQT3VP.UtIEvBICafO",
            "externalId": "777",
            "hikId": "963b3fbdf0df4dcd90e7ad699e08c282",
            "active": false,
            coordinator: null,
            "syncStatus": null
        },
        {
            "name": "Trehucchi Jogos",
            "access_code": null,
            "token": "$2a$10$pYZxO/Zswo6cISB3UIy3lu0jcseLl51aoD82p1vVt3FL5Q/vmglB6",
            "externalId": "888",
            "hikId": "00cbdb77a8fb402d97afe7628c8cad75",
            "active": false,
            coordinator: "Romulo Pires",
            "syncStatus": null
        }
    ])


    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }



    const signOut = async () => {
        try {
            await auth.signOut()
            return true
        } catch (error) {
            return false
        }
    };


    const quemta = () => {

        auth.onAuthStateChanged(() => {

            const user = auth.currentUser;
            if (user) {
                const uid = user.uid;
                console.log('minhapika', uid)
            } else {
                console.log('ta na milonga')
            }
        });

    }

    const onSubmit = (data: any) => {
        setDisableSign(true)

        const username = string_to_slug(data.name)

        setTimeout(() => {
            setDisableSign(false)
            setShowProviderList(true)
        }, 2000);

        // fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'x-api-key': BASE.api.api_key
        //     },
        //     body: JSON.stringify({
        //         name: data.name.replace(/^\s+|\s+$/g, ''),
        //         email: data.email.replace(/^\s+|\s+$/g, ''),
        //         login: username,
        //         password: `AM${Math.random().toString(36).slice(-8)}!`,
        //         role: data.type || 'default'
        //     })
        // })
        //     .then((res) => res.json())
        //     .then((response) => {
        //         console.log(response)
        //         setNewUser({
        //             login: response.login,
        //             password: response.password,
        //             set: true
        //         })
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //     })
    }

    const resetForm = () => {
        setNewUser({
            login: '',
            password: '',
            set: false
        })
        setDisableSign(false)
        reset()
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5" onClick={() => { quemta() }}>Adicionar Prestador</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label onClick={() => { signOut() }}>Pesquisar</Form.Label>
                                    <Form.Control
                                        placeholder="cpf"
                                        {...register('name', { required: true })}
                                    />
                                    {errors.name && (
                                        <span className="label-error">
                                            Você precisa informar um Nome.
                                        </span>
                                    )}
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Button
                                            disabled={disableSign}
                                            type="submit"
                                            className="mb-0 w-100"
                                            onClick={() => clearErrors()}
                                        >
                                            Pesquisar
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                        </Form>
                    </Col>
                </Row>

                {newUser.set && (
                    <>
                        <hr />
                        <Row>
                            <Col className="qr-wrapper">
                                <h3>Usuário cadastrado com sucesso!</h3>
                                <p>
                                    Login: <strong>{newUser.login}</strong>
                                    <br />
                                    Senha provisória: <strong>{newUser.password}</strong>
                                </p>
                            </Col>
                        </Row>
                    </>
                )}

                <hr />

                <Table responsive>
                    <tbody>

                        {showProviderList &&
                            providerList.map((user: any, index) => (
                                <>
                                    <tr key={index} className="unread">
                                        <td>
                                            <h6 className="mb-1">{user.name}</h6>
                                        </td>
                                        <td>
                                            <Link to="#" className="label theme-bg text-white f-12 m-l-15">
                                                Adicionar
                                            </Link>
                                        </td>
                                    </tr>
                                </>

                            ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}
