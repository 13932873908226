import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert,
    Modal,
    ButtonGroup,
    ButtonToolbar
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'


registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { Link, useParams } from 'react-router-dom';

const maskCPF = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

export function AddProvider() {

    const { eventId } = useParams()

    const organization = sessionStorage.getItem('company')


    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [showProviderList, setShowProviderList] = useState(false)

    const [disableSign, setDisableSign] = useState(false)

    const [providerList, setProviderList] = useState<any[]>([])


    const [eventDue, setEventDue] = useState(new Date())

    const [eventName, setEventName] = useState('')

    const [selectedProvider, setSelectedProvider] = useState<any>({})

    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }


    const onSubmit = (data: any) => {
        setDisableSign(true)


        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier}/like`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                companyId: organization
            })
        })
            .then((res) => res.json())
            .then((response) => {
                setProviderList(response)
                setTimeout(() => {
                    setDisableSign(false)
                    setShowProviderList(true)
                }, 2000);
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const addProviderToEvent = (data: any) => {
        setDisableSign(true)

        const eventDate = new Date(eventDue)

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.messenger_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                subject: `Convocação para o evento: ${eventName}`,
                template: 'bepass-game-call',
                companyId: organization,
                eventId: eventId,
                eventDate: `${eventDate.getDate().toString().padStart(2, '0')}/${(eventDate.getMonth() + 1).toString().padStart(2, '0')}/${eventDate.getFullYear()}`,
                eventTime: `${eventDate.getHours()}:${eventDate.getMinutes()}`,
                supplierId: selectedProvider,
                sendEmail: true,
                sendSms: false,
                sendWhats: false
            })
        })
            .then((res) => res.json())
            .then((response) => {



                fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_to_event}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': BASE.api.api_key
                    },
                    body: JSON.stringify({
                        eventId: eventId,
                        supplierId: selectedProvider,
                        jaiminho: response.id,
                        companyId: organization
                    })
                })
                    .then((res) => res.json())
                    .then((response) => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            })
            .catch((error) => {
                console.log(error)
            })


    }



    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as="h5">Adicionar fornecedor</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label>Pesquisar: Procure por qualquer parte do nome</Form.Label>
                                        <Form.Control
                                            placeholder="Nome"
                                            {...register('name', { required: true })}
                                        />
                                        {errors.name && (
                                            <span className="label-error">
                                                Você precisa informar um Nome.
                                            </span>
                                        )}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Button
                                                disabled={disableSign}
                                                type="submit"
                                                className="mb-0 w-100"
                                                onClick={() => clearErrors()}
                                            >
                                                Pesquisar
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                            </Form>
                        </Col>
                    </Row>


                    <Table responsive>
                        <tbody>

                            {showProviderList &&
                                providerList.map((item: any, index) => (

                                    <tr key={index} className="unread">
                                        <td>
                                            <h6 className="mb-1">{item.name}</h6>
                                        </td>
                                        <td>
                                            <Link to="#" onClick={() => { setEventName(item.name); setShowConfirm(true); setSelectedProvider(item.id) }} className="label theme-bg text-white f-12 m-l-15">
                                                Adicionar
                                            </Link>
                                        </td>
                                    </tr>


                                ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Form onSubmit={handleSubmit(addProviderToEvent)}>
                    <Form.Control
                        type="hidden"
                        defaultValue={selectedProvider}
                        {...register('providerId')}
                    />

                    <Modal.Header closeButton>
                        <Modal.Title>Data e hora da convocação</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Data / Hora</Form.Label>
                                <DatePicker
                                    className="form-control"
                                    locale="pt-br"
                                    showTimeInput={true}
                                    timeInputLabel='Hora'
                                    dateFormat="dd/MM/yyyy : HH:mm"
                                    selected={eventDue}
                                    onChange={(date: Date) => setEventDue(date)}
                                />
                                {errors.dueDate && (
                                    <span className="label-error">
                                        Você precisa informar uma data válida
                                    </span>
                                )}
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className='align-right' variant="success" type='submit'>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}
