import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert
} from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import './visitorCreate.sass'

registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { Link, useParams } from 'react-router-dom'

const maskCPF = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

interface Props {
    eventId?: any
}

export function MultipleUserCreate({ eventId }: Props) {

    if (!eventId) {
        eventId = useParams().useParams
    }
    let { supplierId } = useParams()

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [disableSign, setDisableSign] = useState(false)

    const [userRegistred, setUserRegistred] = useState(false)

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }

    const onSubmit = (data: any) => {





        setDisableSign(true)

        if (!eventId) {
            eventId = 'na'
        }


        if (!supplierId) {
            supplierId = sessionStorage.getItem('company') || 'na'
        }

        const companyId = sessionStorage.getItem('company') || 'na'


        const formData = new FormData();
        formData.append("file", data.file[0]);


        fetch(`${BASE.api_user.base_url}${BASE.api_user.user_bulk_create}/${companyId}/${supplierId}/${eventId}`, {
            method: 'POST',
            headers: {

                'x-api-key': BASE.api.api_key
            },
            body: formData,
        })
            .then((res) => res.json())
            .then((response) => {
                setUserRegistred(true)
                setTimeout(() => {
                    setUserRegistred(false)
                    setDisableSign(false)
                }, 3000);
                window.location.reload();
            })
            .catch((error) => {
                setUserRegistred(false)
                setDisableSign(false)
                console.log(error)
            })
    }

    const resetForm = () => {
        setNewUser({
            login: '',
            password: '',
            set: false
        })
        setHasToken('')
        setDisableSign(false)
        reset()
    }

    return (
        <Card>
            <Card.Header>
                <Row>
                    <div className='col'>
                        <Card.Title as="h5">Cadastro de múltiplos colaboradores</Card.Title>
                    </div>
                    <div className="col-auto card-event">
                        <Link to="/samples/planilha_exemplo_fpf.xlsx" rel="noreferrer" target="_blank" className="label theme-bg text-white f-12">
                            Baixar exemplo
                        </Link>
                        {/* <label className="label theme-bg2 text-white f-14 f-w-400 float-right">baixar exemplo</label> */}
                    </div>
                </Row>

            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mb-3">

                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Selecione o arquivo</Form.Label>
                                    <Form.Control type="file" {...register("file")} />
                                </Form.Group>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Button
                                            disabled={disableSign}
                                            type="submit"
                                            className="mb-0 w-100"
                                            onClick={() => clearErrors()}
                                        >
                                            <span hidden={disableSign}>Enviar arquivo</span>
                                            <span hidden={!userRegistred}>Cadastro Completo!</span>

                                            <i hidden={!disableSign || userRegistred}
                                                className="fa fa-spinner fa-spin text-c-green f-20 m-r-15"
                                                title="oiee"
                                            />
                                        </Button>

                                    </Form.Group>

                                </Col>
                            </Row>
                            {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                        </Form>
                    </Col>
                </Row>

                {newUser.set && (
                    <>
                        <hr />
                        <Row>
                            <Col className="qr-wrapper">
                                <h3>Usuário cadastrado com sucesso!</h3>
                                <p>
                                    Login: <strong>{newUser.login}</strong>
                                    <br />
                                    Senha provisória: <strong>{newUser.password}</strong>
                                </p>
                            </Col>
                        </Row>
                    </>
                )}
            </Card.Body>
        </Card>
    )
}
