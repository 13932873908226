import React, { Component, useCallback, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Button,
  Form,
  Alert,
  FloatingLabel
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import logoBePass from '../../assets/images/bepass_logo_default.png'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { FindUserFull } from '../components/findUserFull'
import { TotalUsers } from '../components/totalUsers'
import { DailyAccess } from '../components/dailyAccess'
import { GateStatus } from '../components/gateStatus'
import { useForm } from 'react-hook-form'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import Loader from '../../shared/Loader'

function Login() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const auth = sessionStorage.getItem('auth')
  if (auth === 'true') {
    window.location.href = '/events/bf64ba12-491b-47df-8109-04ae2a0f146e'
  }

  const [loginError, setLoginError] = useState({
    status: false,
    message: ''
  })

  const [loading, setLoading] = useState(false)

  const onSubmit = (data: any) => {
    // if (data.user === 'teste@bepass.com' && data.password === 'test123') {
    //   sessionStorage.setItem('auth', 'true')
    //   return (window.location.href = '/events/bf64ba12-491b-47df-8109-04ae2a0f146e')
    // }

    setLoading(true)

    setLoginError({
      status: false,
      message: ''
    })

    fetch(`${BASE.api.base_url}${BASE.api.access_login}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        login: data.user,
        password: data.password
      })
    })
      .then((resg) => resg.json())
      .then((response) => {

      
        if (response.token) {
          sessionStorage.setItem('auth', 'true')
          sessionStorage.setItem('gate', data.gate)
          sessionStorage.setItem('token', response.id)
          sessionStorage.setItem('name', response.name)
          sessionStorage.setItem('role', response.role)
          sessionStorage.setItem('company', response.companyId)

          setLoading(false)
          // if (response.role === 'coordinator') {
          return (window.location.href = '/events/bf64ba12-491b-47df-8109-04ae2a0f146e')
          
          // }
          // if (response.role === 'supplier') {
          //   return (window.location.href = '/dashboard/provider')
          // }
          // if (response.role === 'admin') {
          //   return (window.location.href = '/dashboard')
          // }
          // if (response.role === 'fanbase') {
          //   return (window.location.href = '/dashboard/fanbase')
          // }
        }

        setLoading(false)
        setLoginError({
          status: true,
          message: 'Usuário ou senha inválidos'
        })
      })
      .catch((error) => {
        setLoading(false)
        setLoginError({
          status: true,
          message: 'Usuário ou senha inválidos'
        })
      })
  }

  return (
    <React.Fragment>
      <div className="auth-wrapper">
        <div className="auth-content">
          <Card className="borderless text-center">

    {loading ? (
 <Loader/>
    ):(
      <Card.Body>
      <div className="mb-4">
        {/* <i className="feather icon-unlock auth-icon" /> */}
        <img src={logoBePass} className='login-logo' />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FloatingLabel
          className="mb-3"
          controlId="floatingPassword"
          label="Usuário"
        >
          <Form.Control
            type="text"
            placeholder="Usuário"
            {...register('user', { required: true })}
          />
          {errors.user && (
            <Alert variant="danger">Por favor, informe um e-mail</Alert>
          )}
        </FloatingLabel>

        <FloatingLabel
          className="mb-3"
          controlId="floatingPassword"
          label="Senha"
        >
          <Form.Control
            type="password"
            placeholder="Senha"
            {...register('password', { required: true })}
          />
          {errors.password && (
            <Alert variant="danger">Por favor, informe uma senha</Alert>
          )}
        </FloatingLabel>

        {loginError.status && (
          <Alert variant="danger">{loginError.message}</Alert>
        )}

        <Form.Group>
          <Button
            type="submit"
            className="mb-0 mt-4 w-100 btn-success"
          // onClick={() => clearErrors()}
          >
            Entrar
          </Button>
        </Form.Group>
      </Form>
    </Card.Body>
    )}
           
         
          </Card>
        </div>
      </div>
      <div className='copyright-botton'>Desenvolvido por <a href="https://www.bepass.com.br" className="href">Bepass</a> | Todos os direitos reservados</div>

    </React.Fragment>
  )
}

export default Login
