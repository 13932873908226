import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    Dropdown,
    Alert,
    Table,
    ToastContainer,
    Toast
} from 'react-bootstrap'
import './findUserFull.sass'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

export function ListUsers() {
    const [userList, setUserList] = useState([])

    const [userSearch, setUserSearch] = useState({
        identifier: '',
        type: 'externalId'
    })

    const [pridedUserList, setPridedUserList] = useState<any[]>([])

    const [userNotFound, setUserNorFound] = useState(false)
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues
    } = useForm()

    const onSubmit = (data: any) => {
        setUserNorFound(false)

        fetch(`${BASE.api.base_url}${BASE.api.access_search}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                name: data.identifier
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                if (response.length < 1) {
                    setUserNorFound(true)
                    setTimeout(() => {
                        setUserNorFound(false)
                    }, 4000)
                }
                setUserList(response)
            })
            .catch((error) => {
                setUserNorFound(true)
                setTimeout(() => {
                    setUserNorFound(false)
                }, 4000)
            })
    }



    const [show, setShow] = useState(false);
    const sendEmail = (data) => {

        fetch(`${BASE.api_user.base_url}${BASE.api_user.send_email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                name: data.firstName,
                email: data.email,
                token: data.token,
                subject: 'Cadastro para acesso Federação Paulista de Futebol',
                template: 'bepass-default-fpf'
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setShow(true)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    return (
        <>
            <Card className="Recent-Users">
                <Card.Header>
                    <Form>
                        <Row className="align-items-center">
                            <Col sm={12} md={2}>
                                <Card.Title as="h5">Colaboradores</Card.Title>
                            </Col>
                            <Col sm={12} md={3}>
                                <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                                    Evento
                                </Form.Label>
                                <Form.Control id="inlineFormInputName" placeholder="Nome do colaborador" />
                            </Col>
                            <Col sm={12} md={3}>
                                <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                                    Data
                                </Form.Label>
                                <Form.Control id="inlineFormInputName" placeholder="CPF" />
                            </Col>

                            <Col sm={12} md={3} lg={2} >

                                <Button className='w-100' type="submit">Pesquisar</Button>
                            </Col>
                        </Row>
                    </Form>

                </Card.Header>
                <Card.Body className="px-0 py-2">
                    <Table responsive hover>
                        <tbody>

                            {pridedUserList &&
                                pridedUserList.map((user: any, index) => (

                                    <tr key={index} className="unread">
                                        <td>
                                            <img className="rounded-circle" style={{ width: '40px' }} src={
                                                user.userAvatar
                                                    ? `data:image/png;base64,${user.userAvatar}`
                                                    : avatar1
                                            } alt="activity-user" />
                                        </td>
                                        <td>
                                            <h6 className="mb-1">{user.name}</h6>
                                        </td>
                                        <td>
                                            <h6 className="mb-1">Segurança</h6>
                                        </td>
                                        <td>
                                            <h6 className="mb-1">333.222.222-10</h6>
                                        </td>
                                        <td>
                                            <h6 className="text-muted biometry-status m-t-10">
                                                biometria
                                                <i className={`fa fa-circle text-c-${user.biometric ? 'green' : 'red'} f-12 m-l-15`} />
                                            </h6>
                                        </td>
                                        <td>
                                            <Link to="#" onClick={() => sendEmail(user)} className="label theme-bg2 text-white f-12">
                                                Enviar e-mail
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to="#" className="label theme-bg4 text-white f-12">
                                                excluir
                                            </Link>

                                        </td>
                                    </tr>

                                ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <ToastContainer containerPosition="fixed" className="p-3 toast-me" position={'bottom-end'}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg='info'>
                    <Toast.Body>E-Mail enviado</Toast.Body>
                </Toast>
            </ToastContainer>
        </>

    )
}
