import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Form, Button, InputGroup, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'
import { EventList } from '../Events/eventList'

function DashCoordinator() {

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [disableSign, setDisableSign] = useState(false)

    const onSubmit = (data: any) => {
        setDisableSign(true)

        const username = data.name

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                login: username,
                password: `AM${Math.random().toString(36).slice(-8)}!`,
                role: data.type || 'default'
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const resetForm = () => {

        setDisableSign(false)
        reset()
    }


    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    const userSyncProgress = (partialSync: any, totalSync: any) => {
        return Math.round((totalSync / partialSync) * 100);
    }


    const [eventList, setEventList] = useState<any[]>([])
    const [nextEventsCount, setNextEventCount] = useState(0)
    const [suppliersCount, setSuppliersCount] = useState(0)

    const getAllEvents = () => {
        setLoading(true)

        const companyId = sessionStorage.getItem('company')

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_list}/${companyId}/false`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setEventList(response)
                setNextEventCount(response.length || 0)

                const enabledCount = response.filter(item => item.biometric).length
                setTotalUsers({
                    withBiometry: enabledCount,
                    withoutBiometry: response.length - enabledCount
                })


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const countSuppliers = () => {
        setLoading(true)

        const companyId = sessionStorage.getItem('company')

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_count}/${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setSuppliersCount(response || 0)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }


    useEffect(() => {
        getAllEvents()
        countSuppliers()
    }, [])

    return (
        <>
            <Row>
                <Col md={4}>
                    <Card>
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-star f-30 text-c-gray" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">{nextEventsCount}</h3>
                                    <span className="d-block text-uppercase">Eventos Próximos</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-grid f-30 text-c-yellow" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">{suppliersCount}</h3>
                                    <span className="d-block text-uppercase">Fornecedores Cadastrados</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {/* <Col md={4}>
                    <Card>
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-users f-30 text-c-green" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">220</h3>
                                    <span className="d-block text-uppercase">Pessoas Cadastradas</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col> */}
            </Row>

            <EventList />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar coordenador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showOrg} onHide={handleCloseOrg}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Local</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('email')}
                                />
                            </Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Data/Hora</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Atualizar
                                    </Button>
                                </Form.Group>
                            </Col>

                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirm}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DashCoordinator
