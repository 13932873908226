import React, { Component, useCallback, useEffect, useState } from 'react'
import {
  FormControl,
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Form,
  InputGroup,
  Button
} from 'react-bootstrap'

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'

import './reports.sass'
import { useParams } from 'react-router-dom'

import { utils, read, writeFile } from 'xlsx';

function Reports() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const { eventId } = useParams()

  const currentUser = sessionStorage.getItem('role')

  if (currentUser === 'default') {
    window.location.href = '/dashboard'
  }

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [disableSign, setDisableSign] = useState(false)

  const [reportType, setReportType] = useState('accesses')


  const handleChange = (event: any) => {
    setReportType(event.target.value);
  };



  const magicMike = (deviceIp:any)=>{
    if(deviceIp === '172.25.10.23' || deviceIp === '172.25.10.24'){
      return 'Recepção'
    }
    return 'Estacionamento'
  }
  const onSubmit = (data: any) => {

    setDisableSign(true)

    if(reportType === 'biometries'){
      return getUsersInfo(data.document)
    }

    const dateFormatPermanenceStart = new Date(startDate)
    const dateFormatPermanenceEnd = new Date(endDate)

    fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_access_withiuser}/${eventId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        document: data.document,
        type: reportType,
        dateFrom: dateFormatPermanenceStart.toISOString().split('T')[0],
        dateTo: dateFormatPermanenceEnd.toISOString().split('T')[0],
      })
    })
      .then((resg) => resg.json())
      .then(async (response) => {

        setDisableSign(false)

        downloadTickets(await response.map((item) => {
          return {
            documento: item.user.document || '',
            nome: `${item.user.firstName} ${item.user.lastName}`,
            acesso: dateConvert(item.createdAt),
            local: magicMike(item.deviceIp),
            direcao: item.status === 'entrance'? 'Entrou':'Saiu'
          }
        }))
      })
      .catch((error) => {
        setDisableSign(false)
        console.log(error)
      })

  }


  const getUsersInfo = (externalId:any) => {
    fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_employees_byEvent}/bf64ba12-491b-47df-8109-04ae2a0f146e/943b0c7a-079f-4ad0-b7e8-a41b34f910c2/true`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          Authorization: CONFIG.API_KEY
      }
  })
      .then((resg) => resg.json())
      .then(async (response) => {
 
        setDisableSign(false)

        downloadTickets(await response.users.map((item) => {
    
          if(externalId){
            console.log('oieoieoi')
            if(externalId === item.document){
              return {
                document: item.document || 'N/A',
                nome: `${item.firstname || ''} ${item.lastname || ''}`,
                email: `${item.email || ''}`,
                funcao: `${item.origintoken || ''}`,
                onboarding: `https://fpf.bepass.com.br/?token=${item.token}`,
                biometria: item.biometry === 'true' ? 'Feita':'Não feita'
              }
            }
          }else{
            return {
              document: item.document || 'N/A',
              nome: `${item.firstname || ''} ${item.lastname || ''}`,
              email: `${item.email || ''}`,
              funcao: `${item.origintoken || ''}`,
              onboarding: `https://fpf.bepass.com.br/?token=${item.token}`,
              biometria: item.biometry === 'true' ? 'Feita':'Não feita'
            }
          }

        }))
      })
      .catch((error) => {
         setDisableSign(false)
          console.log(error)
      })
  }

  const dateConvert = (date: any) => {
    const eventDate = new Date(date)

    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }

    return `${eventDate.toLocaleDateString(
      'pt-br'
    )} ${eventDate.getHours()}:${eventDate.getMinutes()}:${eventDate.getSeconds()}`
  }


  const [downloadFile, setDownloadFile] = useState('')

  const downloadTickets = (reportData: any) => {

    reportData = reportData.filter(function( element ) {
      return element !== undefined;
   });

    const worksheet = utils.json_to_sheet(reportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'relatorio');
    writeFile(workbook, `relatorio.xlsx`);

  }

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Pesquisar</Card.Title>
        <span className="d-block m-t-5">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={2}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control as="select" onChange={handleChange} >
                    <option value="accesses">Acesso</option>
                    <option value="biometries">Cadastros</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={2}>
                <Form.Group>
                  <Form.Label>CPF</Form.Label>
                  <FormControl
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder='Todos'
                    {...register('document')}
                  />
                </Form.Group>
              </Col>

              {reportType === 'accesses' && (
                <>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Data de acesso:</Form.Label>
                      <DatePicker
                        className="form-control"
                        locale="pt-br"
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>à:</Form.Label>
                      <DatePicker
                        className="form-control"
                        locale="pt-br"
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(date: Date) => setEndDate(date)}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}

              <Col md={2}>
                <Form.Label>.</Form.Label>
                <Form.Group>
                  <Button
                    disabled={disableSign}
                    type="submit"
                    className="mb-0 w-100"
                    onClick={() => clearErrors()}
                  >
                    Gerar
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </span>
      </Card.Header>
    </Card>
  )
}

export default Reports
