import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import avatar1 from '../../assets/images/user/avatar-1.jpg'

import clubLogo from '../../images/club_logo.svg'
import tombrasil from '../../assets/images/logos-tom-brasil.jpg'
import alma from '../../assets/images/logo-alma.png'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreate } from '../components/userCreate'
// import { OrganizationCreate } from './createOrganization'
import { useForm } from 'react-hook-form'

import { ProviderCreate } from './createProvider'
import { ProvidersCategories } from './ProvidersCategories'
import { CoordinatorRow } from '../components/coordinatorRow'


const maskCPF = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

function Providers() {
    // const auth = sessionStorage.getItem('auth')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    // const [userList, setUserList] = useState<any[]>([])

    const companyId = sessionStorage.getItem('company')


    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [disableSign, setDisableSign] = useState(false)

    const [supplierList, setSupplierList] = useState<any[]>([])

    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const [show, setShow] = useState(false);

    const [currentSupplierId, setCurrentcurrentSupplierId] = useState();


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    const getAllSuppliers = () => {
        setLoading(true)

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_by_company}/${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setSupplierList(response)

                // const enabledCount = response.filter(item => item.biometric).length
                // setTotalUsers({
                //     withBiometry: enabledCount,
                //     withoutBiometry: response.length - enabledCount
                // })


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }


    const onSubmit = (data: any) => {
        setDisableSign(true)

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                "name": "vasss planeta",
                "trade_name": "Aux-limpeza",
                "cnpj": "0000000000000000",
                "logo": "meu/logo",
                "logo_square": "versao/square",
                "main_color": "#ff00ff",
                "secondary_color": "#ff00ff",
                "status": "active",
                "isActive": true,
                "companyId": companyId,
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const createCoordinator = (data: any) => {
        setDisableSign(true)

        const temp_name = data.name.replace(/^\s+|\s+$/g, '').split(' ')


        const temp_password = `tB${Math.random().toString(36).slice(-8)}!`

        let userId;

        fetch(`${BASE.api_user.base_url}${BASE.api_user.user_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                firstName: temp_name[0],
                lastName: temp_name.slice(1).join(" "),
                email: data.email.replace(/^\s+|\s+$/g, '') || 'a@a.com',
                phoneNumber: data.phoneNumber
                    .replace(/"/g, '')
                    .replace(/'/g, '')
                    .replace(/\(|\)/g, ''),
                "document": data.document.replace(/^\s+|\s+$/g, ''),
                "document_type": "CPF",
                "operator": true,
                "supplierId": data.supplierId,
                "originCompanyId": companyId,
                "externalId": "provider",
                "gender": "na",
                "birthDate": "1990-01-01"
            })
        })
            .then((res) => res.json())
            .then((response) => {

                userId = response.sup
                fetch(`${BASE.api_user.base_url}${BASE.api_user.role}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': BASE.api.api_key
                    },
                    body: JSON.stringify({
                        "userId": userId,
                        "role": "supplier",
                        "companyId": data.supplierId,
                        "companyName": "BePass",
                        "email": data.email,
                        "name": data.name,
                        "login": data.email.replace(/^\s+|\s+$/g, ''),
                        "password": temp_password,
                        "sendMail": true
                    })
                })
                    .then((res) => res.json())
                    .then((response) => {

                        if (response.success) {

                            fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'x-api-key': BASE.api.api_key
                                },
                                body: JSON.stringify({
                                    name: data.name.replace(/^\s+|\s+$/g, ''),
                                    email: data.email.replace(/^\s+|\s+$/g, ''),
                                    login: data.email.replace(/^\s+|\s+$/g, ''),
                                    password: temp_password,
                                    role: 'supplier',
                                    companyId,
                                    supplierId: currentSupplierId,
                                    userId: userId,
                                })
                            })
                                .then((res) => res.json())
                                .then((response) => {
                                    window.location.reload();
                                })
                                .catch((error) => {
                                    console.log(error)
                                })

                        } else {
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })


            })
            .catch((error) => {
                console.log(error)
            })
    }

    const resetForm = () => {
        setDisableSign(false)
        reset()
    }


    useEffect(() => {
        getAllSuppliers()
    }, [])

    return (
        <>
            <Row>
                <Col md={6} xl={8}>
                    <Card className="Recent-Users">
                        <Card.Header>

                            <Row className="align-items-center">
                                <Col sm={12} md={2}>
                                    <Card.Title as="h5">Fornecedores</Card.Title>

                                </Col>
                                {/* <Col sm={12} md={3}>
                                    <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                                        Evento
                                    </Form.Label>
                                    <Form.Control id="inlineFormInputName" placeholder="CNPJ" />
                                </Col>

                                <Col sm={12} md={3}>
                                    <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                                        Evento
                                    </Form.Label>
                                    <Form.Control id="inlineFormInputName" placeholder="Categorias" />
                                </Col>

                                <Col sm={12} md={2}>
                                    <Form.Check
                                        defaultChecked={true}
                                        type="switch"
                                        id="custom-switch"
                                        label="Ativos"
                                    />
                                </Col>


                                <Col sm={12} md={3} lg={2} >
                                    <Button className='w-100' type="submit">Pesquisar</Button>
                                </Col> */}
                            </Row>

                        </Card.Header>
                        <Card.Body className="px-0 py-2">
                            <Table responsive hover>
                                <tbody>

                                    {supplierList &&
                                        supplierList.map((user: any, index) => (
                                            <>
                                                <tr key={index} className="unread">
                                                    <td>
                                                        <h6 className="text-muted biometry-status m-t-10">
                                                            status
                                                            <i className={`fa fa-circle text-c-${user.isActive ? 'green' : 'red'} f-12 m-l-15`} />
                                                        </h6>
                                                    </td>
                                                    <td>
                                                        <h6 className="mb-1">{user.name}</h6>
                                                    </td>
                                                    {/* <td>
                                                        <h6 className="text-muted biometry-status m-t-10">
                                                            segurança
                                                        </h6>
                                                        <h6 className="text-muted biometry-status m-t-10">
                                                            catering
                                                        </h6>
                                                        <h6 className="text-muted biometry-status m-t-10">
                                                            iluminação
                                                        </h6>
                                                    </td> */}

                                                    <td>
                                                        <Link to="#" onClick={() => { setCurrentcurrentSupplierId(user.id); handleShow() }} className="label theme-bg text-white f-12 m-l-15">
                                                            Criar coordenador
                                                        </Link>
                                                        <Link to="#" onClick={() => { handleShowOrg() }} className="label theme-bg2 text-white f-12">
                                                            Editar
                                                        </Link>
                                                        <Link to="#" onClick={() => { handleShowConfirm() }} className="label theme-bg4 text-white f-12 m-l-15">
                                                            X
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <CoordinatorRow supplierId={user.id} companyId={companyId} />
                                            </>

                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} xl={4}>
                    <ProviderCreate />
                    {/* <ProvidersCategories /> */}
                </Col>

            </Row>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(createCoordinator)}>
                    <Form.Control
                        type="hidden"
                        defaultValue={currentSupplierId}
                        {...register('supplierId')}
                    />

                    <Modal.Header closeButton>
                        <Modal.Title>Criar coordenador</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>CPF</Form.Label>

                                <Form.Control
                                    type="text"
                                    placeholder="Documento (CPF)"
                                    {...register('document', { required: true })}
                                    onChange={(event) => {
                                        const { value } = event.target
                                        event.target.value = maskCPF(value)
                                    }}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um CPF.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail</Form.Label>

                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email', { required: true })}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>

                                <Form.Control
                                    type="text"
                                    placeholder="Telefone com DDD"
                                    {...register('phoneNumber')}
                                    onChange={(event) => {
                                        const { value } = event.target
                                        event.target.value = maskPhone(value)
                                    }}
                                /></Col>

                        </Row>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Fechar
                        </Button>
                        <Button variant="primary" type='submit'>
                            Criar
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>

            <Modal show={showOrg} onHide={handleCloseOrg}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Organização</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleCloseOrg}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirm}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Providers
