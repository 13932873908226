import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, Button, Form, ToastContainer, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import {
    BsEmojiSunglasses,
    BsSun,
    BsPeople,
    BsPersonBoundingBox,
    BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { UserCreate } from '../components/userCreate'
import { MultipleUserCreate } from '../components/multipleUserCreate'
import ShowEventProvider from '../Events/showEventProvider'


import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreateAdmin } from '../components/userCreateAdmin'


function CreateAdminUser() {
    const currentUser = sessionStorage.getItem('role')
    const companyId = sessionStorage.getItem('company')

    const [isLoading, setLoading] = useState(false)

    const [show, setShow] = useState(false);

    if (currentUser === 'default') {
        window.location.href = '/dashboard'
    }
    const auth = sessionStorage.getItem('auth')

    if (auth !== 'true') {
        window.location.href = '/'
    }
    const [photoState, setphotoState] = useState<string>('')

    const handleResponse = useCallback((message: string) => {
        setphotoState(message)
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        if (params.get('fixBrowser')) {
            setphotoState('mobile')
        }
    }, [])

    const [pridedUserList, setPridedUserList] = useState<any[]>([])

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const getAllUsers = () => {
        setLoading(true)


        fetch(`${BASE.api.base_url}${BASE.api.access_by_company}/${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setPridedUserList(response)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }



    const sendEmail = (data) => {
        setLoading(true)


        fetch(`${BASE.api_user.base_url}${BASE.api_user.send_email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                token: data.token,
                subject: 'Acesso Administrativo FPF',
                template: 'bepass-user-creation',
                login: data.email.replace(/^\s+|\s+$/g, ''),
                password: data.password,
                companyUrl: 'https://app-fpf.bepass.com.br/',
                companyName: 'FPF'
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setShow(true)
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const deleteUser = (userId) => {

        if(confirm('Tem certeza?') !== true){
            return true
        }
        setLoading(true)


        fetch(`${BASE.api.base_url}${BASE.api.access_edit}/${userId}`, {
            method: 'DELETE',
            headers: {
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                window.location.reload()
            })
            .catch((error) => {
                console.log(error)
            })
    }
    useEffect(() => {
        getAllUsers()
    }, [])


    return (
        <>
            <UserCreateAdmin />
            <Row>
                <Col xs={12} lg={10}>

                    <Card className="Recent-Users">
                        <Card.Header>
                            <Card.Title as="h5">Usuários</Card.Title>

                        </Card.Header>
                        <Card.Body className="px-0 py-2">
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Login</th>
                                        <th colSpan={2}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {pridedUserList &&
                                        pridedUserList.map((user: any, index) => (

                                            user.recoveryKey === 'admin' ? (<></>) : (

                                                <tr key={index} className="unread" >
                                                    <td>
                                                        <h6 className="mb-1">{user.name}</h6>
                                                    </td>
                                                    <td>
                                                        <h6 className="mb-1">{user.login}</h6>
                                                    </td>
                                                    <td >
                                                        {/* <Link to="#" onClick={() => sendEmail(user)} className="label theme-bg2 text-white f-12">
                                                            Enviar senha por email
                                                        </Link> */}

                                                        <Link to="#" onClick={() => deleteUser(user.id)} className="label theme-bg4 text-white f-12">
                                                            excluir
                                                        </Link>

                                                    </td>
                                                </tr>
                                            )

                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
            <ToastContainer containerPosition="fixed" className="p-3 toast-me" position={'bottom-end'}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg='info'>
                    <Toast.Body>E-Mail enviado</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default CreateAdminUser
