import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import InputMask from 'react-input-mask';

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'


registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'

const maskCPF = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

const maskCnpj = (cnpj: any) => {

    cnpj = cnpj.replace(/\D/g, '');

    while (cnpj.length < 14) {
        cnpj = '0' + cnpj;
    }

    cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
    cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');

    return cnpj;
}

export function ProviderCreate() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [disableSign, setDisableSign] = useState(false)

    const [tags, setTags] = useState<any[]>([])

    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        setTags([...tags, value])
        e.target.value = ''
    }

    function removeTag(index) {
        setTags(tags.filter((el, i) => i !== index))
    }

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }



    const signOut = async () => {
        try {
            await auth.signOut()
            return true
        } catch (error) {
            return false
        }
    };


    const quemta = () => {

        auth.onAuthStateChanged(() => {

            const user = auth.currentUser;
            if (user) {
                const uid = user.uid;
                console.log('minhapika', uid)
            } else {
                console.log('ta na milonga')
            }
        });

    }

    const onSubmit = (data: any) => {
        setDisableSign(true)

        const companyId = sessionStorage.getItem('company')

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                "name": data.name,
                "trade_name": data.trade_name,
                "cnpj": data.cnpj,
                "status": "active",
                "isActive": true,
                "companyId": companyId,
            })
        })
            .then((res) => res.json())
            .then((response) => {
                setDisableSign(false)
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const resetForm = () => {
        setNewUser({
            login: '',
            password: '',
            set: false
        })
        setHasToken('')
        setDisableSign(false)
        reset()
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5" onClick={() => { quemta() }}>Criar Fornecedor</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Nome Fantasia</Form.Label>
                                    <Form.Control
                                        type="name"
                                        placeholder=""
                                        {...register('name')}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>CNPJ</Form.Label>
                                    <InputMask mask="99.999.999/9999-99" {...register("cnpj")}>
                                        {/* <Form.Control
                                            type="trade_name"
                                            placeholder=""
                                            {...register('trade_name')}
                                        /> */}
                                        {() => (
                                            <Form.Control type="text" />
                                        )}
                                    </InputMask>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Razão Social</Form.Label>
                                    <Form.Control
                                        type="trade_name"
                                        placeholder=""
                                        {...register('trade_name')}
                                    />
                                </Col>
                            </Row>
                            {/* 
                            <Row className="mb-3">

                                <Col>
                                    <div className="tags-input-container">
                                        <Form.Label>Categorias:</Form.Label>
                                        <input onKeyDown={handleKeyDown} type="text" className="tags-input form-control" placeholder="Digite uma categoria e aperte ENTER" />
                                        {tags.map((tag, index) => (
                                            <div className="tag-item" key={index}>
                                                <span className="text-muted biometry-status m-t-10">{tag}
                                                    <i className="fa fa-times text-c-red close m-l-10" onClick={() => removeTag(index)}></i>
                                                </span>

                                            </div>
                                        ))}
                                    </div></Col>
                            </Row> */}
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Button
                                            disabled={disableSign}
                                            type="submit"
                                            className="mb-0 w-100"
                                            onClick={() => clearErrors()}
                                        >
                                            Cadastrar
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                        </Form>
                    </Col>
                </Row>

            </Card.Body>
        </Card>
    )
}
