export const BASE = {
  onboarding: {
    base_url: 'https://demo.bepass.com.br',
  },
  api: {
    base_url: 'https://federacao-dashboard-api-74hf2txj6q-rj.a.run.app', //
    user_validate: '/user/validate',
    user_create: '/user',
    validate_register_number: '/multiclubes/user/validate',
    get_user_access: '/accesses/findByUser',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_report: '/visitor/report',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    // ADEMIR
    access_create: '/access',
    access_edit: '/access',
    access_find: '/access',
    access_login: '/access/login',
    access_search: '/access/search',
    access_by_company: '/access/bycompany',
    hik_get_status: '/hikvision/local/getDeviceInfo',
    find_user_with_biometry: '/biometry/find/users',
    find_allusers_with_biometry: '/biometry/find/all',
    remove_biometry: '/biometry',
    sync_token: '/biometry/forceSync',
    // access data
    access_approver: '/approvers',
    access_reports: '/access-control/report',
    access_report_entrance: '/access/status',
    access_report_allusers: '/access/allusers',
    log_create: '/logs',
    get_log_from_gate: '/access-control/today',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  local_api: {
    base_url: 'https://6f996a2d5f61.sa.ngrok.io',
    user_validate: '/user/validate',
    user_create: '/user',
    validate_register_number: '/multiclubes/user/validate',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_report: '/visitor/report',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    // ADEMIR
    access_create: '/access',
    access_edit: '/access',
    access_find: '/access',
    access_login: '/access/login',
    access_search: '/access/search',
    hik_get_status: '/hikvision/local/getDeviceInfo',
    find_user_with_biometry: '/biometry/find/users',
    remove_biometry: '/biometry',
    // access data
    access_report_entrance: '/access/status',
    access_report_allusers: '/access/allusers',
    log_create: '/logs',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  api_user: {
    base_url: 'https://federacao-api-user-management-74hf2txj6q-rj.a.run.app',
    role: '/roles',
    user_validate: '/user/validate',
    user_create: '/user',
    coordinator_search: '/user/search/coordinator',
    user_search: '/user/search/document',
    user_bulk_create: '/user/bulk',
    user_edit: '/user',
    user_delete: '/user',
    user_by_provider: '/user/provider',
    validate_register_number: '/multiclubes/user/validate',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_find_by_document: '/visitor/findByDocument',
    access_create: '/access/create',
    access_edit: '/access/edit',
    access_get: '/access',
    log_create: '/logs',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    send_email: '/user/mailSender',
    // ADEMIR
    hik_get_cards: '/hikvision/status/card',
    find_user_with_biometry: '/biometry/find/users',
    remove_biometry: '/biometry',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  api_dashboard: {
    base_url: 'https://federacao-dashboard-api-74hf2txj6q-rj.a.run.app', //https://federacao-dashboard-api-74hf2txj6q-rj.a.run.app
    event_full_data: '/event/allData',
    event_access: '/accesses/byEvent',
    remove_users: '/event/removeUsers',
    today_access: '/accesses/count/daily',
    today_exit_access: '/accesses/count/dailyExit',
    create_event_access: '/accesses/createAccessControl',
    event_get_single_attendance: '/accesses/find/entrance',
    event_access_detailed: '/accesses/detailed',
    event_access_withiuser: '/accesses/lastBy',
    event_create: '/event',
    event_list: '/event/company',
    event_supplier_list: '/event/supplier',
    supplier_employees: '/supplier/getSupplierEmployees',
    supplier_employees_byEvent: '/supplier/getEmployeesByEvent',
    supplier_by_company: '/supplier/getByCompany',
    supplier: '/supplier',
    supplier_count: '/supplier/count',
    supplier_delete: '/supplier',
    supplier_create: '/supplier',
    supplier_to_event: '/supplier/addToEvent',
    companies: '/companies',
    biometry: '/biometry',
    // Messenger
    messenger_create: '/jaiminho',
    messenger_by_event_supplier: '/jaiminho/eventSupplier'
  },
  api_fanbase: {
    base_url: 'https://api-user-management-fzmetuzbjq-ue.a.run.app',
    find_user_photo: '/biometry/find/user',
  }
}
