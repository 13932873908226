import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    Dropdown,
    Table,
    FormSelect,
    Alert,
    Toast
} from 'react-bootstrap'
import './findUserFull.sass'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'


const maskCPF = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

export function FindUserByDocument() {
    const [userList, setUserList] = useState<any>({})

    const [isLoading, setLoading] = useState(false)

    const [resetedBio, setResetedBio] = useState(false)

    const [userSearch, setUserSearch] = useState({
        identifier: '',
        type: 'document'
    })

    const companyId = sessionStorage.getItem('company')


    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues
    } = useForm()

    const onSubmit = (data: any) => {

        setUserList({})
        setLoading(true)
        setUserNotFound(false)

        fetch(`${BASE.api_fanbase.base_url}${BASE.api_fanbase.find_user_photo}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                document: data.identifier.replace(/-/g, '').replace(/\./g, ''),
                companyId: companyId,
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setResetedBio(false)
                setUserList(response)

                if (!response.hikId) {
                    setUserNotFound(true)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
                setUserNotFound(true)
            })
    }

    const [shotToast, setShotToast] = useState(true);

    const [userNotFound, setUserNotFound] = useState(false)

    const toggleToas = () => setShotToast(!shotToast);

    const copyLink = (link: any) => {

        toggleToas()
        navigator.clipboard.writeText(link);


    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as="h5" className="search-header mb-3">
                        Pesquisar Convidado
                    </Card.Title>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col className="mb-2" lg={12} xl={8}>
                                <input
                                    placeholder="CPF"
                                    className="form-control"
                                    {...register('identifier', { required: true })}
                                />
                            </Col>
                            <Col className="mb-2" lg={12} xl={4}>
                                <input
                                    className="btn w-100 btn-primary"
                                    type="submit"
                                    value="Pesquisar"
                                    onClick={() => clearErrors()}
                                />
                            </Col>
                        </Row>
                    </form>
                </Card.Header>

                <Card.Body>
                    {isLoading &&
                        <div className='local-loader'>
                            <i
                                className="fa fa-spinner fa-spin text-c-green f-10 m-r-15"
                                title="-"
                            />
                        </div>}
                    <Table responsive>
                        <tbody>
                            {userNotFound &&
                                <Alert className="mt-5" variant="warning">
                                    Convidado não encontrado
                                </Alert>
                            }
                            {userList &&
                                userList.hikId &&
                                (
                                    <>
                                        <tr key={userList.hikId} className="">
                                            <td>
                                                <img width={100} src={`data:image/png;base64,${userList.userAvatar}`} />
                                            </td>
                                            <td>
                                                <p className='user-data-game'>
                                                    <span>Nome:</span> {userList.firstName} {userList.name} <br />
                                                    <span>Documento:</span> {maskCPF(userList.document)} <br />
                                                    <span>E-mail:</span> {userList.email} <br />
                                                    <span>Data Nasc.:</span> {`${userList.birthDate.split('-')[2]}/${userList.birthDate.split('-')[1]}/${userList.birthDate.split('-')[0]}`}

                                                </p>


                                            </td>
                                        </tr>
                                        {resetedBio &&
                                            <>

                                                <tr>
                                                    <td>

                                                        <img width={100}
                                                            src={
                                                                `https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=https://cadastrofacial.palmeiras.com.br/?token=` +
                                                                userList.token
                                                            }
                                                            className="qr-code"
                                                            alt="Gremio"
                                                        />
                                                    </td>
                                                    <td>


                                                        <hr />
                                                        {/* <p className='user-data-game'>
                                                                Link para cadastro do(a) {userList.firstName} {userList.name}:<br /><br />
                                                            </p> */}
                                                        {/* <Alert onClick={() => copyLink(`Link para cadastro do(a) ${userList.firstName} ${userList.name}: https://cadastrofacial.palmeiras.com.br/?token=${userList.token}`)} className='word-breaker' key={'success'} variant={'success'}>
                                                            https://cadastrofacial.palmeiras.com.br/?token={userList.token}
                                                        </Alert>
                                                        <Toast bg={'Light'} show={shotToast} onClose={toggleToas} delay={3000} autohide>
                                                            <Toast.Body>Link Copiado!</Toast.Body>
                                                        </Toast> */}

                                                        <a
                                                            className="qr-link"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={`https://cadastrofacial.palmeiras.com.br/?token=${userList.token}`}
                                                        >
                                                            Tirar Foto
                                                        </a>




                                                    </td>
                                                </tr>
                                            </>
                                        }
                                    </>
                                )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}
