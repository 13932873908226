import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, ProgressBar, Row } from 'react-bootstrap';

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

import { utils, read, writeFile } from 'xlsx';
import { Link, useParams } from 'react-router-dom';


import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'

import { useForm } from 'react-hook-form'

interface Props {
    supplierId: string,
    supplierName: string,
    biometricTotalHandler: (total: any, done: any) => void

}


const dateConvert = (date: any) => {
    const eventDate = new Date(date)

    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    }

    return `${eventDate.toLocaleDateString(
        'pt-br'
    )} ${eventDate.getHours() - 3}:${eventDate.getMinutes().toString().padStart(2, '0')}`
}

const SupplierBiometryTable = ({ supplierId, supplierName, biometricTotalHandler }: Props) => {


    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const userSyncProgress = (partialSync: any, totalSync: any) => {
        return Math.round((totalSync / partialSync) * 100);
    }


    const [employeeList, setEmployeeList] = useState<any>([])

    const [eventData, setEventData] = useState<any>({
        date: '',
        time: '',
        id: ''
    })

    const { eventId } = useParams()

    const getProviderEmployees = () => {
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_employees_byEvent}/${eventId}/${supplierId}/0`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setEmployeeList(response)
                biometricTotalHandler(response.users?.length, response.biometries)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getCall = () => {
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.messenger_by_event_supplier}/${eventId}/${supplierId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setEventData({
                    date: response.eventDate,
                    time: response.eventTime,
                    id: response.id
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const slugify = (str) => {
        return str
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[^\w]+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "");
    }

    async function getAttendance(employeeId) {

        return await fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_get_single_attendance}/${eventId}/${employeeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                if (response.error) {
                    return ''
                } else {
                    const first = response[0] ? dateConvert(response[0].createdAt) : '--',
                        last = response[1] ? dateConvert(response.pop().createdAt) : '--'

                    return [first, last]
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }
    const downloadExcel = async () => {

        console.log('vai!')
        const jsonData: any = []

        for (const employee of employeeList.users) {

            const attendance = await getAttendance(employee.id)


            jsonData.push(
                {
                    fornecedor: supplierName,
                    funcionario: `${employee.firstName} ${employee.lastName}`,
                    cpf: employee.document,
                    biometria: employee.biometry !== 0 ? 'Sim' : 'Não',
                    funcao: employee.externalId,
                    email: employee.email,
                    cadastro: `https://onboarding.bepass.com.br/?token=${employee.token}`,
                    entrada: attendance ? attendance[0] : '--',
                    saída: attendance ? attendance[1] : '--',
                })

        }

        const worksheet = utils.json_to_sheet(jsonData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        writeFile(workbook, `biometrias-${slugify(supplierName)}.xlsx`);
    };


    const [eventDue, setEventDue] = useState(new Date())

    const [eventName, setEventName] = useState('')

    const [selectedProvider, setSelectedProvider] = useState<any>({})

    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)


    const addProviderToEvent = (data: any) => {

        const eventDate = new Date(eventDue)

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.messenger_create}/${eventData.id}`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                eventId: eventId,
                eventDate: `${eventDate.getDate().toString().padStart(2, '0')}/${(eventDate.getMonth() + 1).toString().padStart(2, '0')}/${eventDate.getFullYear()}`,
                eventTime: `${eventDate.getHours()}:${eventDate.getMinutes()}`,
                supplierId: supplierId,
            })
        })
            .then((res) => res.json())
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })


    }


    useEffect(() => {
        getProviderEmployees()
        getCall()
    }, [])


    if (!employeeList) {
        return (<></>)
    }
    return (
        <>
            <td className='text-center'>
                <h6 className="text-muted biometry-status m-t-10">
                    {employeeList.users?.length}
                </h6>
            </td>

            <td className='text-center'>
                <h6 className="text-muted biometry-status m-t-10">
                    {employeeList.biometries}
                </h6>
            </td>

            <td>

                {employeeList.users?.length && userSyncProgress(employeeList.users?.length, employeeList.biometries) < 100 ?

                    (userSyncProgress(employeeList.users?.length, employeeList.biometries) < 0) ?
                        <ProgressBar variant="danger" label={'erro'} now={100} /> :
                        (<ProgressBar animated label={`${userSyncProgress(employeeList.users?.length, employeeList.biometries)}%`} now={userSyncProgress(employeeList.users?.length, employeeList.biometries)} />) :
                    userSyncProgress(employeeList.users?.length, employeeList.biometries) > 0 ?
                        (
                            <ProgressBar variant="success" label={'completa'} now={100} />
                        ) : ''
                }
            </td>
            <td>
                <p className='pt-3'>{eventData.date}  {eventData.time}
                    (<Link onClick={() => { setShowConfirm(true); }} className="link-coordinator text-c-blue" to={''}>editar</Link>)
                </p>
            </td>
            <td>
                <Link onClick={() => { downloadExcel(); }} className="link-coordinator text-c-blue" to={''}>
                    baixar relatório
                </Link>
            </td>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Form onSubmit={handleSubmit(addProviderToEvent)}>
                    <Form.Control
                        type="hidden"
                        defaultValue={selectedProvider}
                        {...register('providerId')}
                    />

                    <Modal.Header closeButton>
                        <Modal.Title>Editar convocação</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Data / Hora</Form.Label>
                                <DatePicker
                                    className="form-control"
                                    locale="pt-br"
                                    showTimeInput={true}
                                    timeInputLabel='Hora'
                                    dateFormat="dd/MM/yyyy : HH:mm"
                                    selected={eventDue}
                                    onChange={(date: Date) => setEventDue(date)}
                                />
                                {errors.dueDate && (
                                    <span className="label-error">
                                        Você precisa informar uma data válida
                                    </span>
                                )}
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className='align-right' variant="success" type='submit'>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}

export default SupplierBiometryTable
