

import { UserCreate } from '../components/userCreate'
// import { OrganizationCreate } from './createOrganization'
import { useForm } from 'react-hook-form'
import { EventCreate } from './createEvent'
import { AddProvider } from './addProvider'
import SupplierBiometryTable from '../components/supplierBiometryTable'
import { SingleCheckIn } from './singleCheckIn'
import { SingleCreateUser } from './singleCreateUser'


import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Form, Button, FloatingLabel, Modal, ToastContainer, Toast } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'


import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

import '../../assets/scss/forms.sass'
import '../../assets/scss/modal.sass'
import Loader from '../../shared/Loader'

function ShowSingleEvent() {
    const auth = sessionStorage.getItem('auth')

    if (auth !== 'true') {
        window.location.href = '/'
    }


    const dateConvert = (date: any) => {
        const eventDate = new Date(date)
    
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }
    
        return `${eventDate.toLocaleDateString(
            'pt-br'
        )} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
    }

    const { eventId } = useParams()

    const [userList, setUserList] = useState<any[]>([])
    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        todayExit: 0,
        todayAccess: 0
    })

    const [totalExitUsers, setTotalExitUsers] = useState(0)

    const [biometry, setBiometry] = useState({
        withBiometry: 0,
        withoutBiometry: 0,
        total: 0
    })
    


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showReSync, setShowReSync] = useState(false);

    const handleCloseReSync = () => setShowReSync(false);
    const handleShowReSync = () => setShowReSync(true);


    const [showExclude, setShowExclude] = useState(false);

    const handleCloseExclude = () => setShowExclude(false);
    const handleShowExclude = () => setShowExclude(true);

    const [isQRLoaded, seQRtIsLoaded] = useState(false);

    function handleQRLoad() {
        seQRtIsLoaded(true);
    }

    const getAllUsers = () => {
        setLoading(true)
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_access_withiuser}/${eventId}`, {
            method: 'GET',
            headers: {
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setUserList(response)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const getTodayEntrances = () => {
        setLoading(true)
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.today_access}/${eventId}`, {
            method: 'GET',
            headers: {
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setTotalUsers({ ...totalUsers, todayAccess: response.total })
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })

            fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.today_exit_access}/${eventId}`, {
                method: 'GET',
                headers: {
                    Authorization: CONFIG.API_KEY
                }
            })
                .then((resg) => resg.json())
                .then((response) => {
                    setLoading(false)
                    setTotalExitUsers(response.total)
                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error)
                })
    }

    useEffect(() => {
        getAllUsers()
        getBiometryInfo()
        getTodayEntrances()

        setInterval(() => {
            getTodayEntrances()
        }, 10000)
    }, [])


    const [showToast, setShowToast] = useState(false);
    const sendEmail = (data) => {
        setLoading(true)


        fetch(`${BASE.api_user.base_url}${BASE.api_user.send_email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                name: data.firstName,
                email: data.email,
                token: data.token,
                subject: 'Cadastro para acesso Federação Paulista de Futebol',
                template: 'bepass-default-fpf'
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setShowToast(true)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const getBiometryInfo = () => {
      

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_employees_byEvent}/bf64ba12-491b-47df-8109-04ae2a0f146e/943b0c7a-079f-4ad0-b7e8-a41b34f910c2/false`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)

                const enabledCount = response.users.length
                setBiometry({
                    total: enabledCount,
                    withBiometry: response.biometries,
                    withoutBiometry: enabledCount - response.biometries,
                })


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }


    return (
        <>
            <Row>
                <Col md={6} xl={8}>
                    <Card className="Recent-Users">
                        <Card.Header>
                            <Row>
                                <div className='col'>
                                    <Card.Title as="h5"> Últimos Acessos</Card.Title>
                                </div>
                                <div className="col-auto card-event">
                                    <Link to={`/reporting/${eventId}`} className="label theme-bg text-white f-12">
                                        Relatórios
                                    </Link>
                                    {' '}

                                    <Link to={`/provider/events/bf64ba12-491b-47df-8109-04ae2a0f146e/943b0c7a-079f-4ad0-b7e8-a41b34f910c2`} className="label theme-bg2 text-white f-12">
                                        Ver Funcionários
                                    </Link>
                                    {/* <label className="label theme-bg2 text-white f-14 f-w-400 float-right">baixar exemplo</label> */}
                                </div>
                            </Row>
                        </Card.Header>

                        <Card.Body className="px-0 py-2">

                            {isLoading &&
                                <Loader />
                            }
                            <Table responsive hover>
                                <tbody>

                                    {userList &&
                                        userList.map((user: any, index) => (

                                            <tr key={index} className="unread">
                                                <td>
                                                    <img className="rounded-circle" style={{ width: '40px' }} src={
                                                        user.userAvatar
                                                            ? `data:image/png;base64,${user.userAvatar}`
                                                            : avatar1
                                                    } alt="activity-user" />
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">{user.user.firstName} {user.user.lastName}</h6>
                                                    <p className="m-0">cpf: {user.user.document}</p>
                                                </td>
                                                <td>
                                                    acesso em: {dateConvert(user.createdAt)} 
                                                    {user.status === 'exit' ? (
                                                        <i className="feather icon-chevrons-down f-20 m-r-15 text-c-yellow" />
                                                    ):(
                                                        <i className="feather icon-chevrons-up f-20 m-r-15 text-c-blue" />
                                                    )}
                                                </td>
                                            </tr>

                                        ))}


                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} xl={4}>
                    <Card className="card-access">
                        <Card.Header>
                            <h6 className='text-muted'>Eventos Hoje</h6>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div className="row align-items-center justify-content-center card-active">

                                        <h6 className="text-center m-b-10 d-relative">
                                            <span className="text-numbers text-c-blue">{totalUsers.todayAccess}</span>
                                            <i className="feather icon-chevrons-up f-20 m-r-15 text-c-blue" />
                                            <span className="text-muted m-r-5">Entraram</span>
                                        </h6>
                                        {/* <ProgressBar variant="danger" label={'erro'} now={100} /> */}
                                    </div>
                                </Col>
                                 <Col xl={6}>
                                    <div className="row align-items-center justify-content-center card-active">
                                        <h6 className="text-center m-b-10 d-relative">
                                            <span className="text-numbers text-c-yellow">{totalExitUsers}</span>
                                            <i className="feather icon-chevrons-down f-20 m-r-15 text-c-yellow" />
                                            <span className="text-muted m-r-5">Saíram</span>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body className="border-bottom">
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-check-circle f-30 text-c-green" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">{biometry.withBiometry}</h3>
                                    <span className="d-block text-uppercase">Biometrias Completas</span>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Body>
                            <div className="row d-flex align-items-center">
                                <div className="col-auto">
                                    <i className="feather icon-alert-circle f-30 text-c-yellow" />
                                </div>
                                <div className="col">
                                    <h3 className="f-w-300">{biometry.withoutBiometry < 0 ?
                                        0 : biometry.withoutBiometry}</h3>
                                    <span className="d-block text-uppercase">Biometrias Faltantes</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>


            <Modal className='bepass-modal' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enviar e-mail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-qr">
                        <p>Escaneie o Código abaixo ou envie o link por e-mail</p>

                        {!isQRLoaded && <Loader />}
                        <img style={{ display: isQRLoaded ? 'inline-block' : 'none' }} onLoad={handleQRLoad} src="https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=https://cadastrofacial.palmeiras.com.br/?token=jeff-test%26orc=null" />
                    </div>
                </Modal.Body>
                <Modal.Footer className='double-button'>
                    <Button variant="outline-info" onClick={handleClose}>
                        Tirar foto
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Enviar por email
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className='bepass-modal' show={showReSync} onHide={handleCloseReSync}>
                <Modal.Header closeButton>
                    <Modal.Title>Re-sincronizar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-qr">
                        <p>Deseja re-enviar a foto para os dispositivos de reconhecimento?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='double-button'>
                    <Button variant="outline-danger" onClick={handleCloseReSync}>
                        Não
                    </Button>
                    <Button variant="primary" onClick={handleCloseReSync}>
                        Sim
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className='bepass-modal' show={showExclude} onHide={handleCloseExclude}>
                <Modal.Header closeButton>
                    <Modal.Title>Excluir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-qr">
                        <p>Tem certeza de que deseja excluir este funcionário?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='double-button'>
                    <Button variant="outline-danger" onClick={handleCloseExclude}>
                        Não
                    </Button>
                    <Button variant="primary" onClick={handleCloseExclude}>
                        Sim
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer containerPosition="fixed" className="p-3 toast-me" position={'bottom-end'}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg='info'>
                    <Toast.Body>E-Mail enviado</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default ShowSingleEvent
